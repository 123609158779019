<template>
  <div class="home">
    <img src="@/assets/images/main.jpg" alt="">
  </div>
</template>

<script>
  import { ref, watch } from 'vue'
  import { useRouter } from 'vue-router'

  import { useI18n } from 'vue-i18n'

  export default {
    name: 'Home',
    setup() {
      let key = ref(0)
      let locale = ref(useI18n({ useScope: 'global' }).locale)
      watch(locale, () => key.value += 1)

      const router = useRouter()
      const goTo = link => {
        router.push(link)
      }

      return {key, goTo}
    }
  }
</script>

<style lang="scss" scoped>
  .home {
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  @include mobile {
    .home {
      &-block {
        &-content {
          &-name, &-jobs, &-desired-job { font-size: 1.8rem; }
        }
      }
    }
  }

  @include sm-mobile {
    .home {
      &-block {
        &-content {
          p { font-size: 1.5rem; }
          &-name, &-jobs, &-desired-job { font-size: 2rem; }
        }
      }
    }
  }
</style>
